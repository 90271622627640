import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import styled from "styled-components"
// import { Link } from "gatsby"

export default function manifest() {
  return (
    <Layout>
      <SEO title="RumExam | Manifesto | Legal notice" />
      <ManifestWrapper>
        <List>
          <Number>1.</Number>
          <p>
            Best rum is a rum which suits you best simply because organoleptic
            testing is subjective. The following represent my{" "}
            <u>examing criteria</u> and therefore preferences.
          </p>
          <Number>2.</Number>
          <p>
            Rum can quench thirst, although I prefer to savor as I sip it neat.
            I rather focus and exam <u>sipping</u> than mixing rums.
          </p>
          <Number>3.</Number>
          <p>
            Surely price may be an indicator of quality but I never take it for
            granted. As quality has its price so household budget has its
            limitations. I rather exam rums <u>under</u> 50€ per bottle.
          </p>
          <Number>4.</Number>
          <p>
            It's important <u>which</u> raw material and production method was
            used, <u>where</u> the distillate came from, where and <u>how</u>{" "}
            the aging process took place.
          </p>
          <Number>5.</Number>
          <p>
            Natural rum means for me sugar-free and <u>without additives</u>. I
            stand for informed consumer and respect producers being transparent.
          </p>
          <Number>6.</Number>
          <p>
            Color affects perception and may come from exposure to the barrels,
            but as well may be artifically-added. For this reason I will{" "}
            <u>not use</u> color-based US Alcohol and Tobacco Tax and Trade
            Bureau (TTB) classes for rum categorization.
          </p>
          <Number>7.</Number>
          <p>
            Rum &#8211; ron &#8211; rhum. Historic colonial classification
            emphasizes flavor. For some rums this differentiation may be
            accurate, some others simply don't fit the profile. Even if
            outdated, I think <u>&#8216;style&#8217;</u> categorization may be
            used complementary...
          </p>
          <Number>8.</Number>
          <p>
            ...with <u>Luca's Gargano</u> classification based on raw materials
            and distillation method. However, if distillery statement is missing
            then proper classification is impossible.
          </p>
          <Number>9.</Number>
          <p>
            Producers are pretty imaginative in their tasting notes. I get it
            from marketing standpoint. Nevertheless I compare their descriptions
            with <u>nose and taste</u> of an average rum lover ❤️.
          </p>
          <Number>10.</Number>
          <p>
            Informations provided here come from multiple sources. I try my best
            to double-check them. Please feel free to correct me:{" "}
            <a href="mailto: rumexam@gmail.com">rumexam[at]gmail.com</a>. Any
            advice and suggestions will be greatly appreciated 🙏.
          </p>
          <Number>11.</Number>
          <p>
            Enjoy responsibly.{" "}
            <a
              href="https://www.responsibility.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Foundation for Advancing Alcohol Responsibility
            </a>{" "}
            leads the fight to eliminate drunk driving and underage drinking and
            promotes responsible decision-making regarding beverage alcohol.
          </p>
        </List>

        <Copyright>
          <p>
            made with{" "}
            <span role="img" aria-labelledby="heart">
              🥃
            </span>{" "}
            by <a href="mailto: piotrend@gmail.com">piotrEnd</a>
          </p>
        </Copyright>
      </ManifestWrapper>

      <Disclaimer>
        <h3>Legal Notice</h3>
        <p>
          If you have additional questions or require more information about my
          Privacy Policy | Site's Disclaimer | Legal Notice, please feel free to
          contact me by email at{" "}
          <a href="mailto: rumexam@gmail.com">rumexam[at]gmail.com</a>
        </p>
        <h3>Disclaimers for website</h3>
        <p>
          All the information on this website - rumexam.com - is published in
          good faith and for general information purpose only. Website does not
          make any warranties about the completeness, reliability and accuracy
          of this information. Any action you take upon the information you find
          on rumexam.com, is strictly at your own risk. Website will not be
          liable for any losses and/or damages in connection with the use of our
          website. <br />
          From my website, you can visit other websites by following hyperlinks
          to such external sites. While I strive to provide only quality links
          to useful and ethical websites, I have no control over the content and
          nature of these sites. These links to other websites do not imply a
          recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before I have the
          opportunity to remove a link which may have gone 'bad'.
          <br />
          Please be also aware that when you leave my website, other sites may
          have different privacy policies and terms which are beyond my control.
          Please be sure to check the Privacy Policies of these sites as well as
          their "Terms of Service" before engaging in any business or uploading
          any information.
        </p>
        <h3>Data protection</h3>
        <p>
          Website follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
          You can choose to disable cookies through your individual browser
          options.
          <br />
          These data will not be provided to any third parties without the
          user's express approval. Be aware that the transmission of data via
          the internet (e.g. communication by email) is subject to security
          gaps. Complete protection of data from unauthorized access by third
          parties is not possible. The use of contact details published under
          the statutory requirement to provide acknowledgements by third parties
          for the purpose of the transmission of unsolicited advertising and
          informational material is expressly opposed. The site operator
          expressly reserves the right to take legal action against the
          unsolicited mailing of advertising information by way of spamming or
          similar.
        </p>
        <h3>Copyright</h3>
        <p>
          The operator of this site makes every effort to respect the copyrights
          of outside parties and to employ his own material and material in the
          public domain. Downloads and copies of this site may be made for
          private, non-commercial use only. Reproduction, processing,
          distribution and any form of exploitation beyond that permitted by
          copyright law requires the written consent of the author or creator
          concerned. Contributions by third parties are identified as such.
          Should anyone become aware of a possible copyright infringement, I
          kindly request that you inform me of such. Upon becoming aware of such
          a violation I shall remove the respective content immediately.
        </p>
        <h3>Consent</h3>
        <p>
          By using my website, you hereby consent to my disclaimer and agree to
          its terms.
        </p>
        <h3>Update</h3>
        <p>
          Should I update, amend or make any changes to this document, those
          changes will be prominently posted here.
        </p>
      </Disclaimer>
    </Layout>
  )
}

const ManifestWrapper = styled.div`
  max-width: 75%;
  margin: 4rem auto;
  /* font-family: var(--montserrat); */
  font-family: "Montserrat", sans-serif;

  /* @media (min-width: 600px) {
    max-width: 70%;
  }

  @media (min-width: 700px) {
    max-width: 66%;
  }

  @media (min-width: 800px) {
    max-width: 63%;
  }

  @media (min-width: 900px) {
    max-width: 58%;
  } */

  max-width: 88%;

  font-size: 1.15rem;
  width: 100%;
  line-height: 1.35;
  text-align: left;
  hyphens: none;

  @media (min-width: 500px) {
    line-height: 1.4;
  }

  @media (min-width: 700px) {
    font-size: 1.2rem;
  }

  @media (min-width: 760px) {
    max-width: 90%;
  }

  @media (min-width: 880px) {
    line-height: 1.45;
    max-width: 85%;
  }

  @media (min-width: 940px) {
    font-size: 1.25rem;
    line-height: 1.45;
    max-width: 80%;
  }

  @media (min-width: 1132px) {
    max-width: 80%;
  }

  @media (min-width: 1460px) {
    font-size: 1.3rem;
    max-width: 78%;
  }
`

const List = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;

  p {
    margin: 1rem 0;
    line-height: 1.4;
    text-align: justify;

    a {
      color: black;
    }
  }
`

const Number = styled.div`
  display: flex;

  font-size: 1.5rem;
  /* font-family: var(--poiret); */
  font-family: "Poiret One", sans-serif;
  align-items: center;
`
const Copyright = styled.footer`
  margin: 4rem auto;
  text-align: center;

  p {
    display: inline-block;
    font-size: 1rem;
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;
  }

  a {
    text-decoration: none;
    color: black;
    transition: all 0.3s;

    :hover {
      color: #e87f24;
    }
  }
`

const Disclaimer = styled.div`
  margin: 6rem auto 2rem;

  background-color: #fcb712;
  color: black;

  max-width: 568px;
  /* font-family: var(--montserrat); */
  font-family: "Montserrat", sans-serif;
  padding: 20px 50px;

  h3 {
    /* font-family: var(--poiret); */
    font-family: "Poiret One", sans-serif;
    margin: 1rem auto;
    text-align: center;
  }

  h3:first-of-type {
    font-size: 2rem;
  }

  p {
    text-align: justify;
  }

  a {
    /* text-decoration: none; */
    color: black;
  }
`
